html body {
  background-color: white;
}

.hdpi.pac-logo:after {
  content: none;
}

.pac-container {
  border: none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.pac-container .pac-item {
  overflow: hidden;
  width: auto;
  min-height: auto;
  padding: 6px 16px;
  border: none;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
}

.pac-container .pac-item,
.pac-item-query {
  font-size: 1rem;

  font-weight: 400;
  white-space: nowrap;
}

/*** Insurance Logos Scroller for Loader ***/
.logo-slider {
  overflow: hidden;
  max-width: 904px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: absolute;
  width: calc(100vw - 302px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -180%);
}
.logo-slider div {
  display: flex;
  position: relative;
  animation: marquee 25s linear infinite;
}
.logo-slider img {
  display: block;
  margin: 0 24px;
  height: 48px;
}
.logo-slider:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    to right,
    white,
    rgba(255, 255, 255, 0) 80px,
    rgba(255, 255, 255, 0) calc(100% - 80px),
    white
  );
}
@media (max-width: 900px) {
  .logo-slider {
    --image-size: 50px;
    --image-size: min(max(50px, 10vw), 100px);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
/*** Keyboard Datepicker ***/
.datepicker .MuiInputAdornment-root {
  /* pointer-events: none; */
}
